import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { Link } from 'gatsby'
import { IntlProvider } from 'contexts/intl/IntlContext'
import QRCode from 'qrcode'

function Index({ location }) {
  const [text, setText] = useState('')
  const [image, setImage] = useState('')

  const generateQR = async text => {
    setText(text)
    if (text.length === 0) {
      setImage() // Clear the image when text length is 0
    } else {
      try {
        setImage(
          await QRCode.toDataURL(text, {
            scale: 8,
          }),
        )
      } catch (err) {
        Sentry.captureException(err)
        setImage()
      }
    }
  }

  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = image
    link.download = 'qrcode_' + new Date().toISOString() + '.png'
    link.click()
  }

  return (
    <IntlProvider pathname={location.pathname}>
      {({ locale, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={locale}
            title={formatMessage({ id: 'qrcode.generator.title' })}
            description={formatMessage({ id: 'meta.qrcode.generator.description' })}
            keywords={formatMessage({ id: 'meta.qrcode.generator.keywords' })}
            url={formatMessage({ id: 'meta.qrcode.generator.url' })}
            isBlogPost={false}
            ogTitle={formatMessage({ id: 'qrcode.generator.title' })}
            ogUrl={formatMessage({ id: 'meta.qrcode.generator.url' })}
            ogDescription={formatMessage({ id: 'meta.qrcode.generator.description' })}
            ogKeywords={formatMessage({ id: 'meta.qrcode.generator.keywords' })}
            ogLocale={locale}
            twitterTitle={formatMessage({ id: 'qrcode.generator.title' })}
            twitterDescription={formatMessage({ id: 'meta.qrcode.generator.description' })}
            twitterCreator={'Mihir Pipermitwala'}
            siteName={'Mihir Pipermitwala'}
          />
          <div className='container'>
            <div className='is-mobile'>
              <div className='mb-6'>
                <h1 className='is-size-1 has-text-weight-bold has-text-centered has-text-white'>QR Code Generator</h1>
              </div>
              <div className='columns is-full ml-1 mr-1 mt-6'>
                <div className='column is-two-thirds'>
                  <div className='control'>
                    <textarea
                      className='textarea textarea-black is-large is-full has-fixed-size'
                      placeholder='Paste text or URL here'
                      rows='13'
                      onChange={e => generateQR(e.target.value)}
                      value={text}
                    ></textarea>
                  </div>
                </div>
                <div className={`column ${text.length === 0 ? 'hidden' : ''}`}>
                  {image && (
                    <>
                      <img className='image is-fullwidth' src={image} alt='Mihir Pipermitwala QR Code' />
                      <button
                        className='button is-light is-medium is-fullwidth is-responsive has-text-centered mt-2 has-background-light'
                        onClick={handleDownload}
                      >
                        <span>Download QR Code</span>
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className='content'>
                <h2 className='is-size-1 has-text-white'>How to Use:</h2>
                <ol>
                  <li>
                    <strong className='has-text-weight-bol has-text-white'>Text/URL Entry:</strong> Paste or type the
                    content you want to convert into a QR code.
                  </li>
                  <li>
                    <strong className='has-text-weight-bol has-text-white'>QR Code Generation:</strong> As you enter
                    text, a QR code updates in real time.
                  </li>
                  <li>
                    <strong className='has-text-weight-bol has-text-white'>QR Code Display:</strong> The generated QR
                    code is displayed to the right of the input area.
                  </li>
                  <li>
                    <strong className='has-text-weight-bol has-text-white'>Instant Visual Feedback:</strong> See
                    immediate updates as you modify the text.
                  </li>
                  <li>
                    <strong className='has-text-weight-bol has-text-white'>Easy Sharing:</strong> Save the QR code image
                    or share it directly from your browser.
                  </li>
                </ol>
                <h2 className='is-size-1 has-text-white'>Benefits:</h2>
                <ul>
                  <li>
                    <strong className='has-text-weight-bol has-text-white'>Real-Time Updates:</strong> See changes in
                    the QR code as you type.
                  </li>
                  <li>
                    <strong className='has-text-weight-bol has-text-white'>Simplicity:</strong> Intuitive and
                    user-friendly design.
                  </li>
                  <li>
                    <strong className='has-text-weight-bol has-text-white'>Shareability:</strong> Easily share
                    information using QR codes.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='mt-6'>
            <p className='has-text-centered'>
              {formatMessage({ id: 'checkOut.tools' })}{' '}
              <Link to={getPath('tools')}>{formatMessage({ id: 'tools.title' })}</Link>
            </p>
          </div>
        </Layout>
      )}
    </IntlProvider>
  )
}

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Index
